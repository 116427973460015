"use client";
import { useTonAddress } from "@tonconnect/ui-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingIndex from "./app/LoadingPage/loadingIndex";
import PlayIndex from "./app/PlayPage/playIndex";
import { AuthContext } from "./context/AuthContext";
import { UserContext } from "./context/UserContext";
import useServerTimeSync from "./hooks/useServerTimeStamp";
import v3_loader from "./media/v3_loader.gif";
import v3_blue_tick from "./media/v3_tick.png";
import useTelegramBackButton from "./utils/backButton";
import { MINIAPP_LINK, SERVER_ENDPOINT } from "./utils/constants";
function App() {
  const [percentage, setPercentage] = useState(10);
  const [windowHeight, setWindowHeight] = useState(0);
  const connectedAddress = useTonAddress();
  const [windowWidth, setWindowWidth] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const isTimeSynced = useServerTimeSync();
  const [tokenPrice, setTokenPrice] = useState(0);
  const [stepCompleted, setStepCompleted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { user, setUser, setUserEnergy, setUserCredits, setShowReturnPopup } =
    useContext(UserContext);
  const { setJwt, jwt } = useContext(AuthContext);
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  // console.log(user);
  const handelPercentage = () => {
    const duration = 7000; // 5 seconds in milliseconds
    const increment = 0.2;
    const steps = duration / (1000 * increment); // Calculate number of steps based on duration and increment

    const interval = setInterval(() => {
      setPercentage((prevPercentage) => {
        if (prevPercentage >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevPercentage + 100 / steps; // Increment by the percentage equivalent of each step
      });
    }, 1000 * increment); // Interval duration based on increment

    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (user != undefined) {
      if (user.saleopt) {
        setStepCompleted(true);
      } else {
        setStepCompleted(false);
      }
    }
  }, [user]);

  useTelegramBackButton();

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    const handleKeydown = (event) => {
      if (
        event.ctrlKey &&
        (event.key === "+" || event.key === "-" || event.key === "0")
      ) {
        event.preventDefault();
      }
    };

    const handleGestureStart = (event) => {
      event.preventDefault();
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeydown);
    window.addEventListener("gesturestart", handleGestureStart);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("gesturestart", handleGestureStart);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 4300);

    if (!isMobileDevice()) {
      // Redirect or show message for non-mobile users
      navigate("/unsupported");
    }
    //testing
    // navigate("/unsupported");

    const noload = queryParams.get("noload");
    if (noload == "true") {
      setShowLoading(false);
    }

    // Check if stepper was skipped in session storage
    const stepperSkipped = sessionStorage.getItem("stepperSkipped");
    if (stepperSkipped === "true") {
      setStepCompleted(true);
    }
    async function loguser(userData) {
      try {
        await fetch(`${SERVER_ENDPOINT}/log`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });
      } catch (error) {}
    }
    async function fetchTokenPrice() {
      try {
        // const res = await fetch(
        //   "https://6mmcf0xaoi.execute-api.eu-central-1.amazonaws.com/dev/public-token-price",
        //   { method: "GET" }
        // );
        // const parsedRes = await res.json();
        // setTokenPrice(parsedRes.tokenPrice.toFixed(9));
        setTokenPrice(0.000006531);
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchUser(dataString, telegramUsername, userData) {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/v1/user/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            dataString,
            telegramUsername,
            referralCode: searchParams.get("tgWebAppStartParam"),
            userData,
          }),
        });

        const parsedResponse = await response.json();
        const { credits, energy, showReturnPopup, jwt, ...rest } =
          parsedResponse.data;
        setUser({ telegramUsername, ...rest });
        setJwt(jwt);
        setUserEnergy(energy);
        setShowReturnPopup(showReturnPopup);
        setUserCredits(parseInt(credits));
      } catch (error) {
        console.log(error);
      }
    }

    if (typeof window !== "undefined" && window.Telegram?.WebApp && !jwt) {
      const telegram = window.Telegram.WebApp;
      telegram.ready();
      telegram.disableVerticalSwipes();
      const isPremium = !!telegram.initDataUnsafe.user?.is_premium;
      const langCode = telegram.initDataUnsafe.user?.language_code || "en";
      const telegramUsername = telegram.initDataUnsafe?.user?.username;
      loguser(telegram.initDataUnsafe);

      if (process.env.NODE_ENV === "production") {
        if (telegram?.initData && telegram?.initDataUnsafe?.user) {
          fetchUser(telegram.initData, telegramUsername, {
            isPremium,
            langCode,
          });
          // .then(() => setShowLoading(false));
        } else window.location.href = MINIAPP_LINK;
      }

      //For Dev
      if (process.env.NODE_ENV === "development") {
        if (true) {
          fetchUser(
            "query_id=AAHqA_t5AAAAAOoD-3niBPpg&user=%7B%22id%22%3A2046493674%2C%22first_name%22%3A%22Jagjit%22%2C%22last_name%22%3A%22%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1720534359&hash=22ff00f16c6efe8185f28ede2b7c2a8f10a6caac3bfd0a9a53ccd72cc634333b",
            "jagjit12",
            { isPremium, langCode }
          );
        }
      }

      // for production
      // if (true) {
      //   fetchUser(
      //     "query_id=AAHHM71mAAAAAMczvWabxX04&user=%7B%22id%22%3A1723675591%2C%22first_name%22%3A%22Dhruv%22%2C%22last_name%22%3A%22Godambe%22%2C%22username%22%3A%22DhruvGodambe%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1721727194&hash=29fcba07c289604ee91f6947a3e54d0ea2802b0d338225464e73f997efd25f0d",
      //     "jagjit12",
      //     { isPremium, langCode }
      //   );
      // }
    }
    if (window.Telegram.WebApp) {
      const WebApp = window.Telegram.WebApp;
      WebApp.ready();
      WebApp.isClosingConfirmationEnabled = true;
      WebApp.setHeaderColor("#000");
      WebApp.setBackgroundColor("#000");
    }
    if (typeof window !== "undefined") {
      window.Telegram.WebApp.expand();
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }
    fetchTokenPrice();
    setInterval(fetchTokenPrice, 100000);
  }, [navigate, searchParams]);

  useEffect(() => {
    console.log("step completed", stepCompleted);
  }, [stepCompleted]);

  // if (!isTimeSynced) return <div>Something went wrong</div>

  return (
    <div className="App">
      <div id="google_translate_element" style={{ display: "none" }} />
      {showLoading && <div className="enter-background-gif"></div>}

      {showLoading && (
        <>
          <div className="loaderDiv">
            <div className="loadingDiv_Text">
              <div>
                <img src={v3_loader} alt="" />
              </div>
              <div
                style={{
                  fontWeight: "bolder",
                  fontSize: "25px",
                  letterSpacing: "1px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  gap: "2px",
                }}
              >
                <p style={{ textAlign: "center", fontSize: "30px" }}>
                  {" "}
                  PEPE’S FROGBAR
                </p>
                <img src={v3_blue_tick} alt="" height="35px" width="35px" />
              </div>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "15px",
                  letterSpacing: "1px",
                }}
              >
                OFFICIAL TOKEN LAUNCH IN
              </p>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "14px",
                  letterSpacing: "1px",
                  marginTop: "5px",
                }}
              >
                Q4 2024
              </p>
            </div>

            <p style={{ marginBottom: "25px", marginTop: "8px" }}>Follow Us</p>
          </div>
        </>
      )}
      {showLoading ? (
        <LoadingIndex setShowLoading={setShowLoading} />
      ) : (
        // ) : showCountDown ? (
        //   <CountDown CountDown={CountDown} setShowCountDown={setShowCountDown} />
        <div style={{ display: "block" }}>
          <PlayIndex
            tokenPrice={tokenPrice}
            connectedAddress={connectedAddress}
            windowHeight={windowHeight}
            windowWidth={windowWidth}
          />
        </div>
      )}
      {/* this commented code is for testing dont removed this */}
      {/* <div>
        <PlayIndex
          tokenPrice={tokenPrice}
          connectedAddress={connectedAddress}
          windowHeight={windowHeight}
          windowWidth={windowWidth}
        />
      </div> */}

      {/* <div
        style={{
          display:
            (stepCompleted === false || stepCompleted === undefined) &&
              !showLoading
              ? // true&&!showLoading
              "block"
              : "none",
        }}
      // style={{ display: "block" }}
      >
        <Stepper
          setStepCompleted={setStepCompleted}
          user={user}
          setUser={setUser}
          userID={user.userID}
        />
      </div> */}
    </div>
  );
}

export default App;
